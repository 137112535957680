<template>
  <main id="main" class="site-main mt8 ausstellungen">
    <h1 class="page-title">Ausstellungen</h1>
    <Exhibition
      v-for="exhibition of exhibitions"
      :key="exhibition._key"
      v-bind="exhibition"
    />
  </main>
</template>
<script>
import { ref, onMounted } from "vue";
import useSanity from "../use/useSanity";
import Exhibition from "../components/Exhibition.vue";

export default {
  components: { Exhibition },
  setup() {
    const { getExhibitions } = useSanity();
    const exhibitions = ref([]);

    onMounted(async () => {
      exhibitions.value = await getExhibitions();
    });

    return { exhibitions };
  },
};
</script>
<style lang="scss">
@use "../styles/variables" as vars;

.ausstellung {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 50rem;
  padding: 0;
  box-shadow: -0.125rem 0.125rem 0.125rem 0.025rem vars.$light-grey;
  margin-bottom: 2rem;

  p,
  h2 {
    padding: 0 0.15rem;
    margin: 0;
  }

  h2 {
    padding-bottom: 0.1rem;
  }

  div {
    padding: 0.25rem;

    &:first-child {
      display: flex;
      flex-direction: column;
      z-index: 0;
      background-color: vars.$dark-grey;
      color: vars.$white;
      width: 100px;

      p {
        font-size: 1.2rem;
        padding: 0.15rem 0.25rem;
      }
    }
    &:last-of-type {
      display: flex;
      flex-direction: column;
      p:last-child:not(:first-of-type) {
        margin-top: auto;
      }
    }
  }

  .event-description {
    z-index: 1;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 992px) {
  .ausstellung {
    padding: 0.5rem;
    background-color: vars.$light-grey;
    box-shadow: 0 0 0.3rem vars.$light-grey;
    max-width: none;

    div:first-child {
      background-color: vars.$white;
      color: vars.$dark-grey;
      border-radius: 5px 0 0 5px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .ausstellung {
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 1rem;
    }

    div:first-child {
      font-size: 1rem;
      flex-direction: row;
      justify-content: flex-end;
      border-radius: 0;
      width: initial;
    }
  }
}
</style>
