<template>
  <div class="ausstellung">
    <div>
      <p class="von_datum">{{ fromDate }}</p>
      <p id="bis" v-if="to && from !== to">bis</p>
      <p v-if="to && from !== to" id="bis_datum">
        {{ toDate }}
      </p>
    </div>
    <div>
      <h2 id="event-title" class="entry-title">{{ location }}</h2>
      <p class="event-description">{{ desc }}</p>
      <p v-if="link" class="event-description">
        <FontAwesomeIcon :icon="icon" />
        <a :href="link.url" target="_blank" rel="noopener noreferrer">{{
          link.text
        }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export default {
  components: { FontAwesomeIcon },
  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    location: {
      type: String,
    },
    desc: {
      type: String,
    },
    link: {
      type: Object,
    },
  },

  data() {
    return {
      icon: faExternalLinkAlt,
    };
  },
  computed: {
    fromDate() {
      return new Date(this.from).toLocaleDateString("de", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    toDate() {
      return new Date(this.to).toLocaleDateString("de", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>
